exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-arquitetos-jsx": () => import("./../../../src/pages/arquitetos.jsx" /* webpackChunkName: "component---src-pages-arquitetos-jsx" */),
  "component---src-pages-cases-jsx": () => import("./../../../src/pages/cases.jsx" /* webpackChunkName: "component---src-pages-cases-jsx" */),
  "component---src-pages-contato-jsx": () => import("./../../../src/pages/contato.jsx" /* webpackChunkName: "component---src-pages-contato-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-premios-jsx": () => import("./../../../src/pages/premios.jsx" /* webpackChunkName: "component---src-pages-premios-jsx" */),
  "component---src-templates-case-index-jsx": () => import("./../../../src/templates/case/index.jsx" /* webpackChunkName: "component---src-templates-case-index-jsx" */)
}

